<template>
    <div :class="className" :style="{height:height,width:width}" />
  </template>
  <script>
  import * as echarts from 'echarts'
  import { debounce } from '@/utils'
  import { markRaw } from 'vue'
  export default {
    props: {
      className: {
        type: String,
        default: 'chart'
      },
      width: {
        type: String,
        default: '100%'
      },
      height: {
        type: String,
        default: '100%'
      },
      title:{
        type: String,
        default: '温度波动对比'
      },
      colors: {
        type: Array,
        default: ()=>{
          return ['#3ABB83','#F6F853','#F6BF5E', '#F65559']
        }
      },
      yAxis:{
        type:Array,
        default:()=>{
            return  []
        }
      },
      type:{
        type:Object,
        default:{
          label:'温度',
          unit:'℃',
        }
      },
      wdminList:{
        type:Array,
        default:()=>{
            return []
        }
      },
      wdmaxList:{
        type:Array,
        default:()=>{
            return []
        }
      },
    },

    data() {
      return {
        chart:null
      }
    },
    watch:{
      'yAxis':{
        handler() {
           this.refreshData()
        },
        deep:true,
      },
    },
    mounted() {
      this.refreshData()
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 500)
      window.addEventListener('resize', this.__resizeHandler)
    },
    beforeUnmount() {
      if (!this.chart) {
        return
      }
      window.removeEventListener('resize', this.__resizeHandler)
      this.chart.dispose()
      this.chart = null
    },
    methods: {
      initChart() {
          this.chart = markRaw(echarts.init(this.$el))
          this.setOption()
      },
      setOption(){
          let _this = this
          this.chart.setOption({
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: function (params) {
                    var tar0 = params[0];
                    var tar1 = params[1];
                    return tar0.name + '<br/>' + tar0.seriesName + ' : ' + tar0.value + _this.type.unit +'<br/>' +  tar1.seriesName + ' : ' + (Number(tar1.value) + Number(tar0.value)) + _this.type.unit
                }
            },
            grid: {
                left: '4%',
                right: '4%',
                bottom: '8%',
                top:'13%',
                containLabel: true
            },
            color:this.colors,
            yAxis: {
                type: 'value',
                splitLine: { show: false },
                axisLine: { show: true },
                axisTick: {
                    show: true,
                    inside:true,
                },
                axisLabel: {
                    show: true,
                    color: "#000",
                    fontSize: 12,
                },
            },
            xAxis: {
                type: 'category',
                splitLine: { show: false },
                axisTick: {
                    show: false,
                },
                data:this.yAxis,
                axisLabel: {
                    show: true,
                    color: "#333",
                    fontSize: 12,
                },
            },
            series: [
                {
                    name: `${this.type.label}最小值`,
                    type: 'bar',
                    stack: 'Total',
                    barWidth:15,
                    itemStyle: {
                        borderColor: 'transparent',
                        color: 'transparent'
                    },
                    emphasis: {
                        itemStyle: {
                        borderColor: 'transparent',
                        color: 'transparent'
                        }
                    },
                    label:{
                        show:true,
                        position: 'insideTop',
                        color: '#333',
                        fontSize: 12,
                    },
                    data: this.wdminList,
                },
                {
                    name: `${this.type.label}最大值`,
                    type: 'bar',
                    barWidth:15,
                    stack: 'Total',
                    data: this.wdmaxList.map((e,index)=>{
                        return e - this.wdminList[index]
                    }),
                    label:{
                        show:true,
                        position: 'top',
                        color: '#333',
                        fontSize: 12,
                        formatter:(params)=>{
                            return this.wdmaxList[params.dataIndex]
                        }
                    },
                    itemStyle: {
                      color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [{
                        offset: 0,
                        color: '#FF9B0B'
                      },
                      {
                        offset: 1,
                        color: '#FFE11B'
                      }
                      ]),
                    },
                    emphasis: {
                      itemStyle: {
                        opacity: 1,
                      }
                    },
                },
            ]
          })
      },
      refreshData(){
          //刷新数据
          if(this.chart){
            this.setOption();  
          }else{
            this.initChart()
          }  
      }
    }
  }
  
  </script>